import { Heading, VStack, Text } from "@chakra-ui/react";
import React from "react";

function Stellungnahme() {
  return (
    <VStack>
      <Heading>Stellungnahme</Heading>
      <Heading fontSize={"30"} textAlign={"center"}>
        Das Islamische Zentrum Regensburg verurteilt den tödlichen
        Messeranschlag in Solingen aufs Schärfste
      </Heading>
      <Text textAlign={"justify"}>
        Unmittelbar nach dem erschütternden terroristischen Messeranschlages in
        Solingen mit drei Toten und acht Verletzten beim Stadtfest gab der
        Zentralrat der Muslime in Deutschland (ZMD) eine Mitteilung in den
        sozialen Medien heraus: "Müssen alles dafür tun, dass die Wertegrundlage
        unserer freien, offenen und vielfältigen Gesellschaft geschützt wird.
        Hass, Hetze, Extremismus und Radikalismus jeglicher Couleur dürfen in
        Deutschland keinen Platz haben" Das Islamische Zentrum Regensburg
        schließt sich dem ZMD an und verurteil diesen abscheulichen Anschlag
        aufs Schärfste. Wir sind erschüttert und schockiert über den tödlichen
        Messerangriff auf friedliche Bürgerinnen und Bürger in Solingen. Wir
        trauern mit den Hinterbliebenen und Angehörigen und beten für die Opfer
        und die baldige Genesung der Verletzten. Wir im Namen unserer Gemeinde
        sagen klar und deutlich: Nein zum Terror, Nein zum Kalifat, Nein zum
        Antisemitismus, Nein Zur Islamfeindlichkeit, Nein zum Völkermord, Nein
        zu Deportierungen, nein zum Krieg, Nein zum Rassismus, Nein zur Hetze,
        Nein zum Hass, Nein zum Populismus und Nein zur Menschenverachtung. Wir
        im Namen unserer Gemeinde sagen klar und deutlich: Ja zur Toleranz, Ja
        zur Demokratie, Ja zur Vielfalt, Ja zur Freiheit, Ja zum Frieden, Ja zur
        Liebe, Ja zum Respekt, Ja zur Religiosität jeglicher Art, Ja zum Leben
        und Ja zur Gerechtigkeit.
      </Text>
    </VStack>
  );
}

export default Stellungnahme;
